import { useContext, useEffect, useRef, useState } from "react";
import classes from "./Reports.module.css";
import { ReportsContext } from "../../../services/Reports/Reports.context";
import {
  NotFoundContainer,
  NotFoundContainerImage,
  NotFoundText,
} from "../../../styles";
import {
  Box,
  IconButton,
  Tabs,
  Paper,
  Table,
  TableBody,
  TableCell,
  Grid,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Button,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { scrollToTop } from "../../../utility/helper";
import { showNotification } from "../../../shared/Notification/Notification";
import StudentReports from "../../Student/StudentReports/StudentReports";
import ReportViewInfo from "./ReportViewInfo";
import { QuizNewContext } from "../../../services/QuizNew/QuizNew.context";
import DownloadIcon from "@mui/icons-material/Download";

const Reports = ({ title }) => {
  const { onGetReports, onGetStudentsForReports, onDownloadQuizReports } =
    useContext(ReportsContext);
  const { onGetQuiz } = useContext(QuizNewContext);
  const [reports, setReports] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalReports, setTotalReports] = useState(0);
  const [students, setStudents] = useState([]);
  const totalStudents = useRef(0);
  const [studentId, setStudentId] = useState("");
  const [attempt, setAttempt] = useState(null);
  const reportViewRef = useRef(null);
  const studentName = useRef(null);

  const [loading, setLoading] = useState(true);

  const [reportsBy, setReportsBy] = useState("quiz");
  const [quiz, setQuiz] = useState({});
  const previousReportsBy = useRef("quiz");
  const [currentQuizSchema, setCurrentQuizSchema] = useState("newSchema");
  const previousQuizSchema = useRef("newSchema");

  const onChangeReportsBy = (event, newValue) => {
    setSearchKeyword("");
    setReportsBy(newValue);
    previousReportsBy.current = reportsBy;
  };

  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    if (reportViewRef.current) {
      reportViewRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [attempt]);

  useEffect(() => {
    if (reportsBy === "students") {
      getStudentForRep();
      setStudentId("");
    } else {
      getReports();
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    setStudentId("");
    if (reportsBy !== previousReportsBy.current) {
      if (page === 0) {
        if (reportsBy === "students") {
          getStudentForRep();
        } else {
          if (attempt) {
            setAttempt(null);
          }
          getReports();
        }
      } else {
        setPage(0);
      }
    }
  }, [reportsBy]);

  useEffect(() => {
    setStudentId("");
    if (currentQuizSchema !== previousQuizSchema.current) {
      if (page === 0) {
        if (reportsBy === "students") {
          getStudentForRep();
        } else {
          if (attempt) {
            setAttempt(null);
          }
          getReports();
        }
      } else {
        setPage(0);
      }
    }
  }, [currentQuizSchema]);

  const onClickSearch = () => {
    if (attempt) {
      setAttempt(null);
    }
    if (page === 0) {
      if (reportsBy === "students") {
        getStudentForRep();
      } else {
        getReports();
      }
    } else {
      setPage(0);
    }
  };
  const onViewAttempt = (atmpt) => {
    setAttempt(atmpt);
    getQuizData(atmpt?.quiz?._id);
    window.scrollBy({
      top: window.innerHeight, // Scroll down by 100vh
      behavior: "smooth",
    });
    reportViewRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const getQuizData = (quizId) => {
    onGetQuiz(
      quizId,
      (result) => {
        scrollToTop();
        if (result && result.quiz) {
          setQuiz(result.quiz);
        }
      },
      (error) => {
        navigate("/student/quiz");
      },
      true,
      false,
    );
  };

  const getReports = (
    query = `?page=${page + 1}&limit=${rowsPerPage}&reportsBy=${reportsBy}`,
  ) => {
    if (searchKeyword.trim()) {
      query += `&searchKeyword=${searchKeyword}`;
    }
    query += `&schemaType=${currentQuizSchema}`;
    scrollToTop();
    onGetReports(
      query,
      (result) => {
        setLoading(false);
        setReports(result.reports);
        if (students.length && reportsBy !== "students") {
          setStudents([]);
        }
        setTotalReports(result.totalReports);
      },
      (error) => {
        showNotification({ message: error?.message, status: "error" });
        setReports([]);
        setLoading(false);
      },
      true,
      false,
    );
  };

  const getStudentForRep = (
    query = `?page=${page + 1}&limit=${rowsPerPage}`,
  ) => {
    if (searchKeyword.trim()) {
      query += `&searchKeyword=${searchKeyword}`;
    }
    query += `&schemaType=${currentQuizSchema}`;
    scrollToTop();
    onGetStudentsForReports(
      query,
      (result) => {
        setLoading(false);
        setStudents(result.students);
        if (reports.length) {
          setReports([]);
        }
        totalStudents.current = result.totalStudents;
      },
      (error) => {
        showNotification({ message: error?.message, status: "error" });
        setStudents([]);
        setLoading(false);
      },
      true,
      false,
    );
  };

  const onChangeSearchKeyword = (e) => {
    let value = e.target.value;
    setSearchKeyword(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [sort, setSort] = useState({
    type: "desc",
    field: null,
  });

  const onChangeSorting = (fieldToSort) => {
    var currentReports = reports;
    let type = sort.type === "asc" ? "desc" : "asc";
    let fields = ["name", "passPercentage", "failPercentage"];
    if (fields.includes(fieldToSort)) {
      let sortedReports = _.orderBy(currentReports, fieldToSort, type);
      setSort((p) => ({
        ...p,
        type: type,
        field: fieldToSort,
      }));
      setReports(sortedReports);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onClickSearch();
    }
  };

  const onClickFinishReview = () => {
    setAttempt(null);
  };

  const onClickBack = () => {
    setStudentId("");
    setAttempt(null);
  };

  const onChangeQuizSchema = (event, newValue) => {
    setSearchKeyword("");
    setCurrentQuizSchema(newValue);
    previousQuizSchema.current = reportsBy;
  };

  function convertUTCToFormattedIST(utcDateStr) {
    const utcDate = new Date(utcDateStr);

    const ISTOffset = 5 * 60 + 30;

    const istDate = new Date(utcDate.getTime() + ISTOffset * 60 * 1000);

    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    return istDate.toLocaleString("en-IN", options);
  }

  const handleDownloadReportsClick = (id) => {
    const quizData = {
      id,
      isNewQuizSchema: currentQuizSchema === "newSchema",
    };
    onDownloadQuizReports(
      {
        selectedQuizzes:[quizData],
      },
      (result) => {
        try {
          let csvContent = "\n";
          csvContent += `"TotalQuizes",${
            Object.keys(result.reports).length
          }\n\n`;
          for (const quizId in result.reports) {
            if (result.reports.hasOwnProperty(quizId)) {
              const attempts = result.reports[quizId];
              if (attempts.length === 0) {
                break;
              }
              const quiz = attempts[0].quiz;
              csvContent += `"Quiz Name","Total Marks","Pass Percentage","Max Attempts","Available From"\n`;
              csvContent += `"${quiz.name || ""}","${quiz.totalMarks || ""}","${
                quiz.passPercentage || ""
              }","${quiz.attempts || ""}","${
                convertUTCToFormattedIST(quiz.createdAt) || ""
              }"\n`;
              csvContent += `"No. of students attempted","${attempts.length}"\n\n`;
              csvContent += `"Roll Number",First Name","Last Name","Email","Marks Obtained","Percentage","Result","Attempted at"\n\n`;
              attempts.forEach((attempt) => {
                const user = attempt.user;
                if (!user) {
                  console.error(
                    `User object is undefined or null for attemptId: ${attempt._id}`,
                  );
                  return;
                }
                csvContent += `"${user.rollNumber || "-----"}","${user.firstName || ""}","${
                  user.lastName || ""
                }","${user.email || ""}","${attempt.marksObtained || ""}","${
                  attempt.percentage || ""
                }","${attempt.result || ""}","${
                  convertUTCToFormattedIST(attempt.createdAt) || ""
                }"\n`;
              });
              csvContent += "\n\n";
            }
          }
          const blob = new Blob([csvContent], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "reports.csv";
          a.click();
          window.URL.revokeObjectURL(url);
        } catch (e) {
          console.error(e);
        }
      },
      (e) => {
        console.log(e);
      },
      true,
      true,
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        {reportsBy !== "students" ? (
          <p>
            Reports - <h3>{totalReports}</h3>
          </p>
        ) : studentId ? (
          <p>
            Student Name: <h3>{studentName.current}</h3>
          </p>
        ) : (
          <p>
            Total Students - <h3>{totalStudents.current}</h3>
          </p>
        )}
      </Box>
      <Tabs
        sx={{ mb: "15px" }}
        value={currentQuizSchema}
        onChange={onChangeQuizSchema}
        centered>
        <Tab label="New Quiz Schema" value={"newSchema"} />
        <Tab label="Old Quiz Schema" value={"oldSchema"} />
      </Tabs>
      <Typography
        variant="body1"
        align="center"
        sx={{ mt: 2, fontSize: "12px", color: "#FEAE6F" }}>
        Quizzes created before 01-05-2024 comes under old quiz schema
      </Typography>
      <Tabs value={reportsBy} onChange={onChangeReportsBy} centered>
        <Tab label="Reports By Batches" value={"batches"} />
        <Tab label="Reports By Quiz" value={"quiz"} />
        <Tab label="Reports By Students" value={"students"} />
      </Tabs>
      {!studentId && (
        <Grid container spacing={3}>
          <Grid item md={8}>
            <TextField
              margin="normal"
              fullWidth
              id="search"
              variant="standard"
              label="Search By Keyword"
              name="search"
              value={searchKeyword}
              onChange={onChangeSearchKeyword}
              onKeyDown={handleKeyPress}
            />
          </Grid>
          <Grid item md={4} mt={3}>
            <Button variant="outlined" fullWidth onClick={onClickSearch}>
              Search
            </Button>
          </Grid>
        </Grid>
      )}

      {reportsBy !== "students" && reports.length === 0 && !loading && (
        <NotFoundContainer>
          <div>
            <NotFoundText>No Reports Found</NotFoundText>
            <NotFoundContainerImage
              src={require("../../../assets/no_data.png")}
              alt="..."
            />
          </div>
        </NotFoundContainer>
      )}

      {reportsBy === "students" && students.length === 0 && !loading && (
        <NotFoundContainer>
          <div>
            <NotFoundText>No Reports Found</NotFoundText>
            <NotFoundContainerImage
              src={require("../../../assets/no_data.png")}
              alt="..."
            />
          </div>
        </NotFoundContainer>
      )}

      {reports.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S.NO</TableCell>
                <TableCell>
                  {" "}
                  <TableSortLabel
                    direction={
                      sort.type && sort.type === "desc" ? "asc" : "desc"
                    }
                    active
                    onClick={() => onChangeSorting("name")}>
                    {reportsBy === "quiz" ? "Quiz Name" : "Batch Name"}
                  </TableSortLabel>
                </TableCell>
                {reportsBy === "batches" && (
                  <TableCell>No. of Students</TableCell>
                )}
                {reportsBy !== "batches" && (
                  <>
                    <TableCell>Students Attempted</TableCell>
                    <TableCell>
                      <TableSortLabel
                        direction={
                          sort.type && sort.type === "desc" ? "asc" : "desc"
                        }
                        active
                        onClick={() => onChangeSorting("passPercentage")}>
                        Pass Percentage
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <TableSortLabel
                        direction={
                          sort.type && sort.type === "desc" ? "asc" : "desc"
                        }
                        active
                        onClick={() => onChangeSorting("failPercentage")}>
                        Fail Percentage
                      </TableSortLabel>
                    </TableCell>{" "}
                  </>
                )}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.map((report, index) => {
                let {
                  _id,
                  name,
                  passPercentage,
                  failPercentage,
                  totalAttempts,
                } = report;

                return (
                  <TableRow
                    key={_id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}>
                    <TableCell component="th" scope="row">
                      {index + 1 + page * rowsPerPage}
                    </TableCell>
                    <TableCell>{`${name}`}</TableCell>
                    {reportsBy !== "batches" && (
                      <>
                        <TableCell>{totalAttempts}</TableCell>
                        <TableCell sx={{ color: "green" }}>
                          {passPercentage}%
                        </TableCell>
                        <TableCell sx={{ color: "red" }}>
                          {failPercentage}%
                        </TableCell>{" "}
                      </>
                    )}
                    {reportsBy === "batches" && (
                      <TableCell>{report?.students?.length}</TableCell>
                    )}

                    <TableCell>
                      <IconButton
                        onClick={() =>
                          navigate(
                            `view?id=${_id}&reportsBy=${reportsBy}&schema=${currentQuizSchema}`,
                          )
                        }
                        aria-label="edit"
                        color="info">
                        <ViewIcon />
                      </IconButton>
                      {reportsBy === "quiz" && (
                        <IconButton
                          aria-label="download"
                          onClick={() => handleDownloadReportsClick(_id)}>
                          <DownloadIcon />
                          <span style={{ fontSize: "15px" }}>Reports</span>
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  count={totalReports}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  labelRowsPerPage="Reports Per Page"
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}

      {students.length > 0 && !studentId && (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S.NO</TableCell>
                <TableCell>
                  {" "}
                  <TableSortLabel
                    direction={
                      sort.type && sort.type === "desc" ? "asc" : "desc"
                    }
                    active
                    onClick={() => onChangeSorting("name")}>
                    {reportsBy === "quiz"
                      ? "Quiz Name"
                      : reportsBy === "batches"
                      ? "Batch Name"
                      : "Student Name"}
                  </TableSortLabel>
                </TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Quizzes Attempted</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((student, index) => {
                let {
                  _id,
                  firstName,
                  lastName,
                  email,
                  quizAttempts,
                  newQuizAttempts,
                } = student;

                return (
                  <TableRow
                    key={_id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}>
                    <TableCell component="th" scope="row">
                      {index + 1 + page * rowsPerPage}
                    </TableCell>
                    <TableCell>{`${firstName} ${lastName}`}</TableCell>
                    <TableCell>{email}</TableCell>
                    <TableCell>
                      {currentQuizSchema === "oldSchema"
                        ? quizAttempts.length
                        : newQuizAttempts.length}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setStudentId(_id);
                          studentName.current = firstName + " " + lastName;
                        }}
                        aria-label="edit"
                        color="info">
                        <ViewIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  count={totalStudents.current}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  labelRowsPerPage="Reports Per Page"
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
      {studentId && (
        <div>
          <div className={classes.backContainer}>
            <button onClick={onClickBack}>← Back</button>
          </div>
          <StudentReports
            currentQuizSchema={currentQuizSchema}
            onViewAttempt={onViewAttempt}
            id={studentId}
            title={"Student Reports"}
          />
        </div>
      )}
      {attempt && (currentQuizSchema === "newSchema" ? quiz._id : true) && (
        <div ref={reportViewRef}>
          <ReportViewInfo
            quiz={quiz}
            report={attempt}
            onClickFinishReview={onClickFinishReview}
          />
        </div>
      )}
    </>
  );
};

export default Reports;
